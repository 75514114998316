/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

$my-typography: mat.m2-define-typography-config();
@include mat.typography-hierarchy($my-typography);

$orgbrain-blue: #0097a7;
$orgbrain-black: #123340;
$orgbrain-gray: #607d8b;

.mat-mdc-button:not(:disabled).blue-text {
  color: $orgbrain-blue;
}

.blue-text {
  color: $orgbrain-blue;
  &-important {
    color: $orgbrain-blue !important;
  }
}

.black-text {
  color: $orgbrain-black !important;
}

.orange-text {
  color: #f57c00 !important;
}

.gray-text {
  color: #546e7a !important;
}

.white-text {
  color: #ffffff !important;
}

// https://github.com/angular/components/blob/main/src/material/core/theming/_palette.scss
$my-primary: mat.m2-define-palette(mat.$m2-cyan-palette, 700);
$my-accent: mat.m2-define-palette(mat.$m2-orange-palette, 700);
$my-warn: mat.m2-define-palette(mat.$m2-orange-palette, 700);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

mat-card {
  font-size: 14px;
}

html {
  // default color is to make icons with "fill: currentColor" to work properly
  color: #37474fde;
  --mdc-typography-body1-font-size: 14px;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-select-trigger-text-size: 14px;
  --mat-menu-item-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.68);
  --mat-toolbar-container-text-color: rgba(55, 71, 79, 0.87);
  --mat-option-label-text-tracking: normal;
  --mat-sidenav-content-text-color: #37474fde;
  scrollbar-width: thin;
  scrollbar-height: thin;
  mat-form-field[class] {
    display: inline-block;
  }

  a.mat-mdc-icon-button.mat-mdc-icon-button[class] {
    display: inline-flex;
  }

  p {
    margin-block-end: 0;
    margin-block-start: 0;
  }

  .mat-mdc-card-outlined {
    border: none;
  }
}

.mat-mdc-option[class] {
  min-height: 38px;
  .mdc-list-item__primary-text {
    font-size: 14px;
  }
}

.mat-mdc-icon-button[class] {
  --mdc-icon-button-icon-color: #515e66;
}

.mat-expansion-panel[class] {
  --mat-expansion-container-text-color: #515e66;
}

.mat-expansion-panel-header-title[class] {
  --mat-expansion-header-text-color: #123340;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-color: #123340 !important;
}

.mdc-list-item__primary-text[class] {
  --mdc-list-list-item-label-text-color: rgba(55, 71, 79, 0.87);
  text-wrap: wrap;
}

.mdc-list-item:hover .mdc-list-item__primary-text[class] {
  --mdc-list-list-item-hover-label-text-color: rgba(55, 71, 79, 0.87);
}

.mat-mdc-dialog-content[class] {
  max-height: 64vh;
}

.mdc-button[class] {
  padding: 0 16px;
  font-weight: 500;
  border-radius: 18px;
  -webkit-font-smoothing: auto;
  font-smooth: auto;
}

.mdc-button--raised[class],
.mdc-button--outlined[class] {
  min-width: 110px;
}

.mat-mdc-unelevated-button.mat-basic[class]:not(:disabled) {
  color: $orgbrain-blue;
}

.mat-mdc-dialog-actions {
  .mat-mdc-button.mat-unthemed[class]:not(:disabled) {
    color: $orgbrain-blue;
  }
}

.mat-mdc-outlined-button:disabled {
  background-color: #0000001f;
}

.mat-mdc-menu-item[class] {
  .mat-icon-no-color[class],
  .mat-mdc-menu-submenu-icon[class] {
    color: #0000008a;
  }

  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
  }
}

.no-margin-bottom-field {
  div.mat-mdc-text-field-wrapper.mdc-text-field--filled {
    margin-bottom: 0px;
  }
}

// REGULAR TEXT FIELD BEGIN

.move7pxuphack[class] {
  .mat-mdc-select-arrow-wrapper {
    transform: translateY(-7px) !important;
  }
}

div.mat-mdc-text-field-wrapper.mdc-text-field--filled {
  background: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 18px;

  .mat-mdc-select-arrow-wrapper {
    transform: translateY(-1px);
  }

  .mat-mdc-form-field-infix[class] {
    margin-top: 3px;
    padding-top: 13px;
    padding-bottom: 3px;
    min-height: 36px;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mat-mdc-form-field-flex {
    align-items: center;
    .mat-mdc-floating-label {
      top: 20px;
    }
  }
}

orgbrain-multi-dropdown-with-search {
  .mat-mdc-form-field-flex {
    align-items: center;
    .mat-mdc-floating-label {
      top: 28px;
    }
  }
}
// REGULAR TEXT FIELD END

// HINT BEGIN
.mat-mdc-form-field[class] {
  .mat-mdc-form-field-bottom-align::before {
    display: none !important;
  }

  div.mat-mdc-form-field-hint-wrapper {
    position: relative;
  }
}
.mat-mdc-form-field-hint-wrapper[class] {
  padding-left: 0;
  mat-hint[class] {
    margin-top: -16px;
    line-height: 12px;
  }
}
.mat-mdc-form-field-error-wrapper[class] {
  mat-error[class] {
    margin-top: -16px;
    margin-left: -16px;
    line-height: 12px;
  }
}
.mat-form-field-appearance-outline {
  display: flex;
  .mat-mdc-form-field-hint-wrapper[class] {
    padding-left: 16px;
    mat-hint[class] {
      margin-top: 1px;
    }
  }

  &.mat-mdc-form-field-type-mat-input {
    .mat-mdc-form-field-hint-wrapper[class] {
      mat-hint[class] {
        margin-top: 4px;
      }
    }
    .mat-mdc-form-field-error-wrapper[class] {
      mat-error[class] {
        margin-left: 0;
        margin-top: 4px;
        line-height: 12px;
      }
    }
  }
}
// HINT END

// OUTLINE BUTTON STYLE BEGIN
div.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  .mat-mdc-form-field-infix {
    padding-top: 0;
    padding-bottom: 0;
  }

  div.mat-mdc-form-field-flex {
    align-items: center;
    div.mat-mdc-form-field-infix {
      min-height: 42px;
      display: flex;
      align-items: center;
      textarea {
        margin-top: 6px;
      }
    }

    .mat-mdc-select-value {
      padding: 12px 0;
    }

    label {
      padding-bottom: 15px;
      max-width: calc(100% - 30px) !important;
    }

    .mdc-floating-label--float-above {
      margin-left: -4px;
      padding-top: 0px;
      overflow: hidden;
      background: white;
      padding: 0px 2px;
      text-overflow: ellipsis;
      max-width: 130% !important;
    }

    .mdc-notched-outline__notch {
      width: 100% !important;
      border-top-width: 1px;
      border-top-style: solid;
    }
  }
  &.mdc-text-field--focused {
    .mdc-notched-outline__notch {
      border-top-width: 2px !important;
    }
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none !important;
  }

  div.mat-mdc-form-field-hint-wrapper {
    position: relative;
  }
}
// OUTLINE BUTTON STYLE END

// CHECKBOX STYLE BEGIN
.mat-accent {
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
    .mdc-checkbox__checkmark {
      color: white !important;
    }

    .mdc-checkbox__mixedmark[class] {
      border-color: white !important;
    }
  }
}

// CHECKBOX STYLE END

.mat-mdc-form-field-icon-suffix {
  display: flex;
  align-items: center;
}

.justify-content-between {
  .mdc-list-item__primary-text,
  .mat-mdc-menu-item-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

button.mat-mdc-icon-button.mat-mdc-icon-button,
a.mat-mdc-icon-button.mat-mdc-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .mat-mdc-button-touch-target {
    width: 40px;
  }
}

.mdc-button-fix {
  .mdc-button__label {
    display: flex;
  }
}

.mat-mdc-menu-content.mat-mdc-menu-content {
  padding: 0;
}

.mat-mdc-paginator-page-size {
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    border: none;
  }
}

// THIS DOES NOT BELONG HERE!!!
body {
  margin: 0;
}

* {
  letter-spacing: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
mat-card-title {
  color: $orgbrain-gray;
}

// Custom CSS that should be eslewhere!

h1 {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 400;
  margin-top: 20px;
}

@media (min-width: 481px) {
  /*  need to do these to avoid glitches when loading */
  body,
  .mat-sidenav-content,
  .mat-sidenav-container {
    background-color: #eef1f3;
  }

  .defaultCard[class] {
    margin: 40px auto 0;
  }
}

mat-card-title {
  margin-bottom: 20px !important;
}

mat-bottom-sheet-container {
  position: fixed;
  bottom: 0;
  left: 5px;
}

.padding-y-mobile-8 {
  @media (max-width: 700px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.button-row {
  align-items: center;
  justify-content: space-around;
  margin: 20px 20px 20px 0;
}

.padding-x-64 {
  padding-left: 64px !important;
  padding-right: 64px !important;
  @media (max-width: 700px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.mat-mdc-outlined-button.mat-primary:not(:disabled) {
  border-color: #0097a7 !important;
}

.mat-mdc-outlined-button.mat-primary:disabled {
  border-color: transparent;
}

.accordheader {
  font-size: 20px;
  color: #123340;
  line-height: 24px !important;
  font-weight: 400;
}
.padding-x-38-responsive {
  padding-left: 38px !important;
  padding-right: 38px !important;
  @media (max-width: 700px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.padding-x-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
  @media (max-width: 700px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.padding-x-40-responsive {
  padding-left: 40px !important;
  padding-right: 40px !important;
  @media (max-width: 700px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  &.variable-padding-small-activate {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.forcing-no-padding-in-expansion-panel-body-in-mobile {
  .mat-expansion-panel-body {
    @media screen and (max-width: 700px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

.overrideMobile768 {
  .padding-x-64 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .padding-x-40-responsive {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .padding-x-40 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .forcing-no-padding-in-expansion-panel-body-in-mobile {
    .mat-expansion-panel-body {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .meeting-notes-panel {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

.padding-x-48-with-tables {
  padding-left: 48px !important;
  padding-right: 48px !important;
  @media (max-width: 700px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.action-button {
  min-width: 105px;
}

.letter-avatar {
  background-color: $orgbrain-blue;
  color: white;
  // font-size: mat-font-size($typography-config, title);
  // font-family: mat-font-family($typography-config);
  display: flex;
  align-items: center;
  justify-content: center;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px 5px;
}

.example-spacer {
  flex: 1 1 auto;
}

.spacer {
  flex: 1 1 auto;
}

.defaultCard {
  margin: 0 auto;
  width: 100%;
  max-width: 840px;
  margin-bottom: 23px;
  box-sizing: border-box;
}

.removemargin {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

// Temp hack for backgrounds after Material 15 refactoring
mat-dialog-container,
.mat-select-panel,
mat-card,
.mat-menu-content {
  background: white;
}

.mat-button-base {
  background: none;
}

// PJL new stuff
.mdc-dialog__surface {
  padding: 32px; // From Kaj design for dialog
  @media (max-width: 699px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.mdc-dialog .mdc-dialog__content[class] {
  padding: 0;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: $orgbrain-black;
}

.mdc-dialog__title {
  padding-left: 0px !important;
}

.mat-mdc-standard-chip {
  border-radius: 8px !important;
}

.mdc-button.tonal-button[class] {
  background-color: #cceaed;
  color: #004d55;
  &:disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

mat-dialog-container.mat-mdc-dialog-container div.container[class] {
  margin-top: 0px;
}

.fat-button {
  line-height: 36px !important;
  display: inline-block !important;
  height: unset !important;
  border-radius: 1000px !important;
}

.mdc-list-item.mdc-list-item--with-one-line[class] {
  height: auto;
  min-height: 35px;
  p {
    margin: 0;
  }
}

.hacked-radio-move-left {
  .mdc-radio__background[class] {
    position: absolute;
    top: 1px;
    left: -2px;
  }
}

orgbrain-toolbar-org-chooser-body {
  div.mat-mdc-text-field-wrapper.mdc-text-field--filled {
    margin-bottom: 0;
  }

  .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-left: 0px;
  }
}

.mdc-data-table__pagination-total[class],
.mdc-data-table__pagination-rows-per-page-label[class],
.mdc-data-table__cell[class],
.mdc-data-table__header-cell[class] {
  color: #546e7a;
}

.mat-tabs-orgbrain {
  .mdc-tab__text-label {
    font-weight: normal;
    font-size: 13px;
    color: #546e7a !important;
  }

  .mat-mdc-tab.mdc-tab {
    height: 58px !important;
    ul {
      li:last-of-type {
        padding: 7px 0 10px 0;
      }
    }
  }
}

.mat-tabs-orgbrain.mat-tabs-orgbrain-48 {
  .mdc-tab__text-label {
    font-size: 14px;
  }
  .mat-mdc-tab.mdc-tab {
    height: 48px !important;
  }
}

.mdc-dialog__title::before {
  display: none !important;
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end[class] {
  margin-left: 8px;
}

.mdc-dialog__actions[class] {
  padding-top: 16px;
  min-height: 36px;
}

.snack-container {
  .text-content {
    word-break: break-word;
  }
}

.fix-button-with-icon-right[class] {
  flex-direction: row-reverse;
  // padding-right: 0;
  mat-icon {
    margin-right: 0;
    margin-left: 8px;
  }
}

.kaj-design {
  .mat-icon.mat-warn[class] {
    color: #c63c11;
  }

  mat-list-item {
    p {
      color: #0097a7;
      padding-left: 10px;
    }
  }
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple[class] {
  height: 36px;
  width: 36px;
}

.mat-mdc-button .mat-mdc-button-touch-target[class],
.mat-mdc-unelevated-button .mat-mdc-button-touch-target[class],
.mat-mdc-raised-button .mat-mdc-button-touch-target[class],
.mat-mdc-outlined-button .mat-mdc-button-touch-target[class] {
  height: 36px;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target[class] {
  height: 40px;
}

.mat-mdc-autocomplete-panel {
  mat-pseudo-checkbox {
    display: none;
  }
}
.cdk-overlay-pane {
  min-width: 94px !important;
}

.expadedAgendaItemHeader {
  .mat-expansion-indicator {
    display: none;
  }

  mat-icon.threeDotsIcon {
    position: absolute;
    right: 15px;
    top: calc(50% - 12px);

    @media (max-width: 699px) {
      right: 3px;
    }
  }

  mat-panel-description {
    padding-right: 8px !important;
  }
}

.mdc-menu-surface--open[class] {
  padding-top: 0;
  padding-bottom: 0;
}

.force-wrap-mat-option {
  background: black;
  overflow: auto !important;
  .mdc-list-item__primary-text {
    text-overflow: unset;
    word-break: break-word;
  }
}

ngx-extended-pdf-viewer div.zoom mat-form-field.mat-mdc-form-field,
ngx-extended-pdf-viewer div.zoom mat-form-field.mat-mdc-form-field * {
  box-sizing: border-box !important;
}

.move-badge-left-28 {
  .mat-badge-content {
    margin-left: -28px !important;
    z-index: 1000;
  }
}
